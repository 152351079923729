<template>
  <div class="image-input-container">
    <h3 v-if="title" class="form-input-title">
      {{ title }}
      <span v-if="inlineSubtitle" class="inline-subtitle">{{ inlineSubtitle }}</span>
    </h3>
    <BasicButton v-if="!storedFile"
                 class="file"
                 :button-text="$t('DIAS.FILE_CHOOSE')"
                 :button-class="'base-button'"
                 @click="addFile"
    />

    <UploadedImage v-if="storedFile"
                   :file="storedFile"
                   :remove="() => clearFile()" />
    <p v-if="errorText" class="text-error">{{ errorText }}</p>
  </div>
  <BasicModal
    v-if="modalActive"
    :button-right-disabled="!storedFile"

  >
    <template #content="">
      <FileDropbox
        :file-input-id="(Math.random() * 100000).toString()"
        :allowed-mime-types="['image/jpeg','image/png', 'image/webp', 'image/gif']"
        :style-preset="'small'"
        :callback="setFile"
      />
    </template>
  </BasicModal>
</template>

<script>

import BasicButton from '@/components/elements/basic/BasicButton.vue'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import UploadedImage from '@/components/elements/inputFields/UploadedImage'
import FileDropbox from '@/components/elements/FileDropbox.vue'

export default {
  name: 'ImageInput',
  components: {
    BasicModal,
    BasicButton,
    UploadedImage,
    FileDropbox
  },
  props: {
    title: {
      type: String,
      default: null
    },
    inlineSubtitle: {
      type: String,
      default: ''
    },
    image: {
      type: Boolean,
      default: true,
    },
    startFile: {
      type: [Object, String],
      default: null
    },
    errorText: {
      type: String,
      default: null
    },
    mediaFiles: {
      type: Array,
      default: () => []
    }
  },
  emits: ['file'],
  data() {
    return {
      modalActive: false,
      modalController: new ModalController(),
      storedFile: typeof this.startFile === 'string' ? null : this.startFile
    }
  },
  computed: {
    isUrl() { return typeof file === 'string' }
  },
  watch: {
    startFile: function() {
      this.convertIfString(this.startFile)
    },
  },
  mounted() {
    this.convertIfString(this.startFile)
  },
  methods: {
    convertIfString(file) {
      if (typeof file === 'number') {
        return
      }
      this.storedFile = file
      if (typeof file === 'string' && this.mediaFiles) {
        const mediaFile = this.mediaFiles.find(x => x.url === file)
        if (mediaFile) {
          this.$emit('file', mediaFile.id)
        }
      }
    },
    addFile() {
      this.modalController.setModal(
        BASIC_MODAL_CONTENT.CREATEDIA,
        () => {
          this.modalController.resetActiveModal()
          this.modalActive = false
        },
        null,
        null,
        MODAL_NAMES.ADD_IMAGE
      )
      this.modalActive = true
    },
    setFile(data) {
      this.storedFile = data[0]
      this.$emit('file', this.storedFile)
    },
    clearFile() {
      this.storedFile = null
      this.$emit('file', null)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.file{
  white-space: nowrap;
  background: white;
  color: var(--blue_dark);
}

.image-input-container{
  margin: rem(20) rem(0) rem(35);
}
</style>

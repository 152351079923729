<template>
  <div class="uploaded-image-wrapper" :style="cssVars">
    <img v-if="image" class="uploaded-image" :src="imagePreview"/>
    <video
      v-else
      ref="video"
      :src="imagePreview"
      class="uploaded-image"
      controls
    ></video>
    <img v-if="remove !== null" :src="require('../../../assets/icons/icn_reject.svg')" class="remove-button" @click="remove"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
export default {
  name: 'UploadedImage',
  props: {
    file: {
      type: [Blob, String],
      required: true
    },
    image: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 200
    },
    remove: {
      type: Function,
      default: null
    }
  },
  setup(props) {
    const imagePreview = ref('')
    if (typeof props.file === 'string') {
      imagePreview.value = (' ' + props.file).slice(1)
    } else {
      updateImage()
    }

    function updateImage() {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(props.file)
      fileReader.onload = imageLoad

      function imageLoad() {
        imagePreview.value = fileReader.result
      }
    }

    watch(() => props.file, (curr, prev) => updateImage())

    const cssVars = computed(() => {
      return {
        '--image-width': props.width.toString() + 'px',
        '--image-height': props.height.toString() + 'px'
      }
    })


    return {
      imagePreview,
      cssVars
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.uploaded-image-wrapper{
  position: relative;
  width: var(--image-width) ;
  height: var(--image-height);
  border-radius: rem(10);
}

.uploaded-image {
  width: var(--image-width);
  height: var(--image-height);
  object-fit: contain;
  background-color: black;
  border-radius: rem(10);
}

.remove-button{
  position: absolute;
  display: flex;
  top: 10px;
  right: 10px;
  width: rem(45);
  height: rem(45);
  padding: rem(10) rem(10);
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}


</style>
